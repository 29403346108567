<template>
  <v-container>
    <v-card elevation="0" class="pa-4">
      <v-form ref="form" v-model="isValid" @submit.prevent="save">
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="editedItem.title"
                  label="Project title"
                  :rules="rules.name"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.projectTypeId"
                  label="Select Project Type"
                  :items="projectTypes"
                  item-text="title"
                  class="mt-n4"
                  variant="outlined"
                  item-value="id"
                  :rules="rules.select"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.subjectId"
                  label="Select Subject"
                  :items="subjects"
                  item-text="title"
                  class="mt-n4"
                  variant="outlined"
                  item-value="id"
                  :rules="rules.select"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-date-input
                  v-model="editedItem.deadline"
                  label="Deadline Date"
                  clearable
                  prepend-icon=""
                  density="compact"
                  class="mt-n4"
                  :min="today"
                  variant="outlined"
                  :rules="rules.item"
                  @change="checkIfToday"
                ></v-date-input>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="editedItem.time"
                  :active="menu2"
                  :focus="menu2"
                  density="compact"
                  variant="outlined"
                  label="Deadline time"
                  prepend-icon=""
                  class="mt-n4"
                  :rules="rules.item"
                  readonly
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    activator="parent"
                    transition="scale-transition"
                  >
                    <v-time-picker
                      v-if="menu2"
                      v-model="editedItem.time"
                      full-width
                      format="24hr"
                      :allowed-hours="allowedHours"
                      :allowed-minutes="allowedMinutes"
                    ></v-time-picker>
                  </v-menu>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  v-model="editedItem.description"
                  label="Project Description"
                  class="mt-n4"
                  variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.academicLevelId"
                  label="Select Academic Level"
                  :items="academicLevels"
                  item-text="title"
                  variant="outlined"
                  item-value="id"
                  :rules="rules.select"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.citationId"
                  label="Select Citation Style"
                  :items="citations"
                  item-text="title"
                  variant="outlined"
                  item-value="id"
                  :rules="rules.select"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.sources"
                  label="Select Sources"
                  :items="numberItems"
                  class="mt-n4"
                  variant="outlined"
                  :rules="rules.select"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.pages"
                  label="Select Pages"
                  :items="pageNumbers"
                  class="mt-n4"
                  :readonly="!editedItem.cpp"
                  variant="outlined"
                  item-text="title"
                  item-value="id"
                  :rules="rules.select"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-autocomplete
                  v-model="editedItem.spacing"
                  label="Select Spacing"
                  :items="spacingOptions"
                  class="mt-n4"
                  variant="outlined"
                  item-text="title"
                  item-value="id"
                  :rules="rules.item"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="editedItem.wordCount"
                  label="Word Count"
                  class="mt-n4"
                  readonly
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="editedItem.email"
                  label="Enter Email"
                  class="mt-n4"
                  :rules="rules.email"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-file-input
                  v-model="editedItem.files"
                  label="Upload Files(optional)"
                  prepend-icon=""
                  counter
                  multiple
                  class="mt-n4"
                  show-size
                  variant="outlined"
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="editedItem.amount"
                  label="Payable Amount(USD)"
                  readonly
                  class="mt-n4"
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <div class="ml-n2 text-caption">
          <span class="text-grey  ">
            By posting order and signing up I agree to the
            <v-btn
              @click="terms"
              variant="text"
              class="text-none ml-n1"
              size="x-small"
              color="blue"
              >user agreement</v-btn
            >
            <span class="ml-n1"> and </span>
            <v-btn
              @click="policies"
              variant="text"
              class="text-none ml-n2"
              size="x-small"
              color="blue"
              >Privacy Policies</v-btn
            >
          </span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="elevated"
          @click="save"
          :disabled="!isValid"
          class="mt-n1 mr-n2"
          :loading="loading"
        >
          <template v-slot:prepend>
            <img src="/images/paypal.png" alt="icon" width="25" height="25" />
          </template>
          <span class="mx-5"> Submit & Pay </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import BaseService from "../services/base.service";
import PaymentService from "../services/payment.service.js";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isValid: false,
    orderId: null,
    time: null,
    menu2: false,
    numberItems: Array.from({ length: 20 }, (_, i) => i + 1),
    pageNumbers: Array.from({ length: 300 }, (_, i) => ({
      title: i + 1,
      id: i + 1,
    })),

    spacingOptions: [
      { title: "Single", id: "single" },
      { title: "1.5", id: "1.5" },
      { title: "Double", id: "double" },
    ],

    rules: {
      name: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a title.";
        },
      ],
      email: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a email.";
        },
      ],
      item: [
        (value) => {
          if (value?.length >= 3) return true;
          return "this is required";
        },
      ],
      select: [
        (value) => !!value || "Please select a item",
        (value) => (value >= 1 && value <= 1000) || "Invalid selected.",
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: false,
        key: "id",
      },
      { title: "Name", value: "title", key: "title" },
      { title: "Slug", value: "slug", key: "slug" },
      { title: "Category", value: "category.name", key: "category.name" },
      { title: "Description", value: "description", key: "description" },
      { title: "Actions", key: "actions", sortable: false },
    ],

    isTodaySelected: false,
    currentHour: new Date().getHours(),
    currentMinute: new Date().getMinutes(),
    today: new Date().toISOString().substr(0, 10),
    editedIndex: -1,
    academicLevels: [],
    projectTypes: [],
    citations: [],
    subjects: [],
    cpp: {},
    editedItem: {
      title: "",
      projectTypeId: "",
      subjectId: "",
      deadline: new Date(),
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      academicLevelId: "",
      citationId: "",
      sources: "",
      pages: "",
      spacing: "",
      wordCount: "",
      amount: "",
      description: "",
      files: [],
    },
    defaultItem: {
      title: "",
      projectTypeId: "",
      subjectId: "",
      deadline: null,
      academicLevelId: "",
      citationId: "",
      time: null,
      sources: "",
      pages: "",
      spacing: "",
      wordCount: "",
      amount: "",
      description: "",
      files: [],
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.pages": "updateWordCount",
    "editedItem.deadline": "fetchCpp",
    "editedItem.academicLevelId": "fetchCpp",

    "editedItem.pages": "updateAmount",
    "editedItem.cpp": "updateAmount",

    "editedItem.spacing": {
      handler(newVal, oldVal) {
        this.handleChanges();
      },
      deep: true,
    },
  },

  mounted() {
    const token = this.$route.query.token;
    const orderId = this.$route.query.id;

    if (token) {
      this.capturePayment(token, orderId);
    }
  },

  created() {
    this.getAcademicLevels();
    this.getCitations();
    this.getProjectTypes();
    this.getSubjects();
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    terms() {
      this.$router.push(this.$route.query.redirect || "/user-agreement");
    },

    policies() {
      this.$router.push(this.$route.query.redirect || "/privacy-policies");
    },

    checkIfToday() {
      const selectedDate = new Date(this.editedItem.deadline);
      const currentDate = new Date();
      this.isTodaySelected =
        selectedDate.toDateString() === currentDate.toDateString();
    },
    allowedHours(hour) {
      if (this.isTodaySelected) {
        return hour >= this.currentHour;
      }
      return true;
    },
    allowedMinutes(minute) {
      if (
        this.isTodaySelected &&
        this.editedItem.time.split(":")[0] == this.currentHour
      ) {
        return minute >= this.currentMinute;
      }
      return true;
    },

    handleChanges() {
      this.updateWordCount();
      this.updateAmount();
    },

    updateWordCount() {
      const { pages, spacing } = this.editedItem;
      // const pages = this.editedItem.pages;
      // const spacing = this.editedItem.spacing;
      if (pages === null || spacing === null) {
        this.editedItem.wordCount = 0;
        return;
      }

      let wordsPerPage;
      switch (spacing) {
        case "single":
          wordsPerPage = 550;
          break;
        case "1.5":
          wordsPerPage = 275;
          break;
        case "double":
          wordsPerPage = 275;
          break;
        default:
          wordsPerPage = 275;
      }

      const calculatedWordCount = pages * wordsPerPage;
      this.editedItem.wordCount = isNaN(calculatedWordCount)
        ? ""
        : calculatedWordCount;
    },

    getAcademicLevels() {
      return BaseService.getAcademicLevels().then(
        (response) => {
          if (response.status == 200) {
            this.academicLevels = response.data.data;
            this.loading = false;
          } else {
            this.subcategories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.subcategories = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getCitations() {
      return BaseService.getCitations().then(
        (response) => {
          if (response.status == 200) {
            this.citations = response.data.data;
            this.loading = false;
          } else {
            this.citations = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.citations = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getProjectTypes() {
      return BaseService.getProjectTypes().then(
        (response) => {
          if (response.status == 200) {
            this.projectTypes = response.data.data;
            this.loading = false;
          } else {
            this.projectTypes = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.projectTypes = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getSubjects() {
      return BaseService.getSubjects().then(
        (response) => {
          if (response.status == 200) {
            this.subjects = response.data.data;
            this.loading = false;
          } else {
            this.subjects = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.subjects = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    fetchCpp() {
      const { deadline, academicLevelId } = this.editedItem;
      if (deadline && academicLevelId) {
        this.loading = true;
        return BaseService.showCpp({ deadline, academicLevelId }).then(
          (response) => {
            if (response.status == 200) {
              this.cpp = response.data.data;
              this.editedItem.cpp = response.data.data.cost;
              this.loading = false;
            } else {
              this.cpp = [];
              this.loading = false;
              console.log(response.data.console.error);
            }
          },
          (error) => {
            this.cpp = [];
            console.log(error);
            if (error.status == 404) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else {
              this.$store.dispatch("alert/error", error.message);
            }
          }
        );
      } else {
        this.editedItem.cpp = null;
        this.updateAmount();
      }
    },

    updateAmount() {
      const { pages, cpp, spacing } = this.editedItem;
      if (pages && cpp !== null) {
        this.editedItem.amount = pages * cpp;
        spacing === "single"
          ? (this.editedItem.amount *= 2)
          : this.editedItem.amount;
        this.editedItem.amount = isNaN(this.editedItem.amount)
          ? ""
          : this.editedItem.amount;
      } else {
        this.editedItem.amount = "";
      }
    },

    editItem(item) {
      this.editedIndex = this.subcategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.subcategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      SubategoriesService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.subcategories.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSave() {
      console.log("hiii", this.editedItem);
      if (this.validate()) {
        BaseService.postOrder(this.editedItem).then(
          (response) => {
            if (response.status == 201) {
              this.orderId = response.data.data.id;
              this.pay();
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    save() {
      if (this.validate()) {
        this.convertFilesToBase64(this.editedItem.files).then((base64Files) => {
          this.editedItem.files = base64Files;
          this.handleSave();
        });
      }
    },

    convertFilesToBase64(files) {
      return new Promise((resolve) => {
        const base64Files = [];
        if (files && files.length > 0) {
          files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              base64Files.push(e.target.result);
              if (base64Files.length === files.length) {
                resolve(base64Files);
              }
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(file);
          });
        } else {
          resolve([]);
        }
      });
    },

    pay() {
      if (this.orderId) {
        PaymentService.createPayment({ orderId: this.orderId }).then(
          (response) => {
            if (response.status === 200) {
              window.location.href = response.data.data.approval_url;
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status === 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else {
              this.$store.dispatch("alert/error", error.message);
            }
          }
        );
      } else {
        this.$store.dispatch("alert/error", "Order not found");
      }
    },

    capturePayment(token, orderId) {
      BaseService.capturePayment({ token: token, orderId: orderId }).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              "alert/success",
              "Payment completed successfully!"
            );
            this.$router.push({
              name: "order-confirmation",
              params: { orderId: id },
            });
          } else {
            this.$store.dispatch("alert/error", "Payment capture failed.");
          }
        },
        (error) => {
          console.error("Payment capture error:", error);
          this.$store.dispatch(
            "alert/error",
            error.response.data.message || "Payment capture error."
          );
        }
      );
    },
  },
};
</script>