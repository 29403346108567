<template>
  <div>
    <v-row no-gutter class="ma-1">
      <v-col cols="12" md="12" class="d-flex text-justify justify-center">
        <v-card width="820" variant="flat" rounded="sm" class="pa-1">
          <template v-slot:title>
            <div class="text-blue font-weight-bold">User Agreement</div>
            <div class="text-caption">Effective Date: 1st October 2024</div>
          </template>
          <template v-slot:text>
            <v-alert>
              <p class="text-caption text-justify">
                This User Agreement ("Agreement") governs your use of US Writing
                Services ("Company," "we," "us," "our"). By using our services
                or accessing our website, you agree to comply with the terms
                outlined below. Please read this Agreement carefully before
                placing an order or using our site.
              </p>
            </v-alert>
            <div class="">
              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >1. Service Overview:</span
                >
                <span class="text-caption text-justify">
                  US Writing Services provides custom writing, editing, and
                  related services for academic and professional purposes. By
                  using our services, you acknowledge that the work delivered is
                  intended for reference, research, or personal use only, and
                  not for submission as your own academic work unless otherwise
                  allowed by your institution.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >2. User Responsibilities:</span
                >
                <span class="text-caption text-justify">
                  When you use our services, you agree to:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Provide accurate, up-to-date, and complete information
                        when placing an order.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Use the content and materials we provide solely for
                        lawful purposes.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Avoid copying, redistributing, or publishing the work
                        we provide without explicit written permission from US
                        Writing Services.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Acknowledge that the services are for educational
                        assistance, and any misuse of the content, such as
                        academic dishonesty or plagiarism, is your
                        responsibility.</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >3. Order Placement and Payment:</span
                >
                <span class="text-caption text-justify">
                  To place an order, users must submit a request through our
                  website or by contacting us directly. Payment is required
                  before work begins. All payments are processed securely, and
                  by placing an order, you agree to our pricing and refund
                  policies.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >4. Refund Policy:</span
                >
                <span class="text-caption text-justify">
                  We are committed to delivering high-quality work that meets
                  your specifications. However, refunds may be issued under
                  specific conditions, such as:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Failure to meet the agreed deadline.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >If the content delivered does not align with the
                        instructions provided, and revisions do not resolve the
                        issue. Refunds will not be issued for services that have
                        been completed and approved by the client.</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >5. Confidentiality:</span
                >
                <span class="text-caption text-justify">
                  We are committed to maintaining your privacy and ensuring
                  confidentiality regarding all information you share with us.
                  Please refer to our Privacy Policy for details on how we
                  handle and protect your personal data.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >6. Limitation of Liability:</span
                >
                <span class="text-caption text-justify">
                  US Writing Services is not liable for any indirect,
                  incidental, or consequential damages that result from the use
                  or inability to use our services. Our maximum liability to any
                  user shall not exceed the amount paid for the service.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold">7. Amendments:</span>
                <span class="text-caption text-justify">
                  We reserve the right to modify this Agreement at any time.
                  Your continued use of our services constitutes your acceptance
                  of any changes.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold">Contact Us:</span>
                <span class="text-caption text-justify">
                  If you have questions regarding this Agreement, contact us at:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Email: writers@uswritingservices.com</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Phone: +1 (424) 240-5459</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>
            </div>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
};
</script>

<style scoped>
.text-blue {
  color: #1e88e5;
}
</style>
