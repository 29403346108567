const features = [
    {
        id: 1,
        name: "Essays Writing Services",
        details: {
            imageUrl: "images/illustration-features-tab-1.svg",
            title: "Essays Writing Services",
            description: "We’ve been writing essays for over a decade, putting words into people’s mouths, and keyboards. Want to know more? You’re in the right place.",
            link: "#post-order",
        },
    },
    {
        id: 2,
        name: "Research Papers",
        details: {
            imageUrl: "images/illustration-features-tab-2.svg",
            title: "Research Papers",
            description: "Research writing is our flagship service. We boast an aggregate total of several centuries’ worth of expertise in teaching, writing, and research. No matter what you’re struggling with, chances are, one of us has taught it, and or, written several dozen papers on it.",
            link: "#post-order",
        },
    },
    {
        id: 3,
        name: "Dissertations & Thesis",
        details: {
            imageUrl: "images/illustration-features-tab-3.svg",
            title: "Dissertations & Thesis",
            description: "Unlike essays or smaller-scale theses, dissertations must be written on the basis of the style guides provided by your institution. Given that universities maintain databases of dissertations created within their walls indefinitely, let Uswriters help you stand out.",
            link: "#post-order",
        },
    },
    {
        id: 4,
        name: "Business Plans & Editing",
        details: {
            imageUrl: "images/illustration-features-tab-1.svg",
            title: "Business Plans & Editing",
            description: "Struggling with your business plan? Our expert writing services can help you craft a compelling and comprehensive plan that meets your academic needs.",
            link: "#post-order",
        },
    },
];

export default features;
