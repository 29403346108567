<script setup></script>

<template>
  <!-- Hero Section Start -->
  <div class="container mx-auto px-5 overflow-x-hidden lg:overflow-x-visible">
    <section class="grid grid-cols-none lg:grid-cols-2 pb-16 pt-8 items-center">
      <div class="lg:w-5/6 order-1 lg:order-none">
        <h1
          class="text-4xl xl:text-5xl font-bold font-theme-heading text-center lg:text-left"
        >
          WE'RE HIGH QUALITY ESSAY WRITERS
        </h1>
        <p
          class="lg:text-xl mt-10 font-theme-content text-theme-grayish-blue text-center lg:text-left"
        >
          We write every custom essay from scratch, and never pragerise. Ever.
          now we can also help with your online courses and tests.
        </p>
        <div class="flex justify-center lg:justify-start mt-10">
          <LinkButton btn-type="primary" link="#post-order"
            >Get Your Essay Now</LinkButton
          >
        </div>
      </div>
      <div
        style="z-index: 1"
        class="relative order-2 lg:order-none mb-20 lg:mt-0 lg:mb-0 lg:-mr-10"
      >
        <img class="z-10 w-full" src="/images/illustration-hero.svg" />
        <div
          class="-z-10 bg-theme-primary h-52 w-full sm:h-80 sm:w-full rounded-l-full absolute -right-28 md:-right-48 -bottom-8"
        ></div>
      </div>
    </section>
  </div>
  <!-- Hero Section End -->
</template>
