import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FeaturesComponent from '@/sections/FeatureSection.vue'
import DownloadComponent from '@/sections/DownloadSection.vue'
import FaqComponent from '@/sections/FaqSection.vue'
import SubscribeComponent from '@/sections/SubscribeSection.vue'
import LoginPage from '../components/auth/LoginPage.vue'
import ForgottenPassword from '../components/auth/ForgottenPassword.vue'
import ResetPassword from '../components/auth/ResetPassword.vue'
import VerifyEmail from '../components/auth/VerifyEmail.vue'
import RegisterComponent from '../components/auth/RegisterComponent.vue'
import DashboardComponent from '../components/AdminDashboard.vue'
import RolesView from '@/views/RolesView.vue'
import UsersView from '@/views/UsersView.vue'
import OrderView from '@/views/OrderView.vue'
import PaymentView from '@/views/PaymentView.vue'
import DashboardView from '@/views/DashboardView.vue'
import CitationView from '@/views/CitationsView.vue'
import CppsView from '@/views/CppsView.vue'
import AcademicLevels from '@/views/AcademicLevels.vue'
import SubjectView from '@/views/SubjectsView.vue'
import ProjectType from '@/views/ProjectTypes.vue'
import WriterOrders from '@/views/WriterOrders.vue'
import ClientOrders from '@/views/ClientOrders.vue'
import BlogView from '@/views/BlogsView.vue'
import BlogPage from '@/views/BlogPage.vue'
import PrivacyPolicies from '@/views/PrivacyView.vue'
import UserAgreement from '@/views/AgreementView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  { path: '/features', component: FeaturesComponent },
  { path: '/post-order', component: DownloadComponent },
  { path: '/faq', component: FaqComponent },
  { path: '/subscribe', component: SubscribeComponent },

  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: false,
  },
  {
    path: '/forgotten-password',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    props: false,
  },
  {
    path: '/blog-page',
    name: 'BlogPage',
    component: BlogPage,
    props: false,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    props: false,
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: VerifyEmail,
    props: false,
  },
  {
    path: '/privacy-policies',
    name: 'PrivacyPolicies',
    component: PrivacyPolicies,
    props: false,
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: UserAgreement,
    props: false,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    props: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    props: false,
    children: [
    {
      path: '/dashboard',
      name: 'DashboardView',
      component: DashboardView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/users',
      name: 'Users',
      component: UsersView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/roles',
      name: 'Roles',
      component: RolesView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/blogs',
      name: 'Blogs',
      component: BlogView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/orders',
      name: 'OrderView',
      component: OrderView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/payments',
      name: 'PaymentView',
      component: PaymentView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/cpps',
      name: 'CppsView',
      component: CppsView ,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/citations',
      name: 'CitationView',
      component: CitationView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/academic-levels',
      name: 'AcademicLevels',
      component: AcademicLevels,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/project-types',
      name: 'ProjectType',
      component: ProjectType,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/subjects',
      name: 'SubjectView',
      component: SubjectView,
      meta: { requiresAuth: true },
      props: false,
    },

    {
      path: '/client',
      name: 'ClientOrders',
      component: ClientOrders,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/writer',
      name: 'WriterOrders',
      component: WriterOrders,
      meta: { requiresAuth: true },
      props: false,
    },
    ]
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
