<template>
  <v-app id="inspire">
    <v-app-bar
      elevation="0"
      clipped-left
      style="position: fixed"
      class="text-white font-weight-bold"
      color="grey-lighten-4"
    >
      <v-app-bar-nav-icon
        color="primary"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div href="/" class="text-primary">
        <span class="font-weight-black text-h5"> US </span
        ><span class="text-caption"> WRITERS </span>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <!-- Left Drawer -->
    <v-navigation-drawer
      style="position: fixed"
      class="text-caption"
      elevation="0"
      clipped
      v-model="drawer"
    >
      <v-list lines="one" class="ml-n3 mx-3">
        <v-list-item
          v-for="(item, i) in Blogs"
          :key="i"
          rounded="lg"
          class="text-caption my-1"
          @click="toggleActive(item)"
        >
          <v-list-item-title
            class="text-overline font-weight-bold texxt-none ml-4 mt-1"
            v-text="item.title"
          ></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="ma-15">
      <!-- Instead of router-view, display the selected blog description -->
      <v-card variant="flat" class="pa-2" v-if="selectedItem">
        <template v-slot:title>
          <div class="text-primary font-weight-bold">{{ selectedItem.title  }}</div>
          <div class="text-caption"><span class="text-primary font-weight-bold">Course:</span>{{ " "+ selectedItem.subject.title }}</div>
          <div class="text-caption"><span class="text-primary font-weight-bold">Question:</span>{{ " "+ selectedItem.slug }}</div>
        </template>
        <v-divider :thickness="7" color="primary"></v-divider>
        <v-card-text class="text-caption text-none text-justify">
          <div v-html="selectedItem.description"></div>
        </v-card-text>
      </v-card>
      <div v-else>
        <p>Select a blog to see its description.</p>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import BlogService from "../services/blogs.service.js";
export default {
  data: () => ({
    drawer: null,
    selectedItem: null,
    Blogs: [],
    loading: false,
  }),

  computed: {
    user() {
      return (
        this.$store.getters["auth/user"] ||
        JSON.parse(localStorage.getItem("user"))
      );
    },
  },

  created() {
    this.getBlogs();
  },

  methods: {
    toggleActive(item) {
      this.selectedItem = item;
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getBlogs() {
      return BlogService.openBlogs().then(
        (response) => {
          if (response.status == 200) {
            this.Blogs = response.data.data;
            // Set the first blog as the selected item
            if (this.Blogs.length > 0) {
              this.selectedItem = this.Blogs[0];
            }
            this.loading = false;
          } else {
            this.Blogs = [];
            this.loading = false;
            console.error(response.data.console.error);
          }
        },
        (error) => {
          this.Blogs = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },
  },
};
</script>

