<script setup>
import { ref } from "vue";
import router from "@/router";

let mobileNav = ref(false);

let toggleMobileNav = () => {
  mobileNav.value = !mobileNav.value;
};

let goToLogin = () => {
  router.push("/login");
};
let goToRegister = () => {
  router.push("/register");
};
let goToBlog = () => {
  router.push("/blog-page");
};
</script>

<template>
  <!-- Navbar Start -->
  <div class="container mx-auto px-5">
    <header class="flex justify-between py-12 items-center">
      <div href="/" class="text-primary">
        <span class="font-weight-black text-h5"> US </span
        ><span class="text-caption"> WRITERS </span>
        <!-- <a href="/"><img src="/images/logo-bookmark.svg" alt="Site Logo" /></a> -->
      </div>

      <nav class="flex justify-end flex-col md:flex-row">
        <!-- Menu Toggler -->
        <button
          @click="toggleMobileNav()"
          type="button"
          class="flex md:hidden focus:outline-none"
        >
          <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
            <path
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            ></path>
          </svg>
        </button>

        <!-- Desktop Menu -->
        <ul
          class="space-x-10 font-theme-heading font-medium items-center hidden md:flex flex-col space-y-7 md:space-y-0 md:flex-row"
        >
          <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#" v-smooth-scroll>Home</a>
          </li>
          <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#features" v-smooth-scroll>Services</a>
          </li>
          <!-- <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#subscribe" v-smooth-scroll>How it Works</a>
          </li> -->
          <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#about" v-smooth-scroll>About Us</a>
          </li>
          <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#login" @click="goToLogin" v-smooth-scroll>Login</a>
          </li>
          <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#login" @click="goToRegister" v-smooth-scroll>Register</a>
          </li>
          <li
            class="uppercase hover:text-theme-secondary transition duration-200"
          >
            <a href="#login" @click="goToBlog" v-smooth-scroll>Blog</a>
          </li>
          <li
            class="uppercase bg-theme-secondary px-6 py-2 text-white rounded shadow-md hover:bg-white border-2 border-transparent hover:border-theme-secondary hover:text-theme-secondary cursor-pointer transition duration-200"
          >
            <a href="#post-order" v-smooth-scroll>Order Now</a>
          </li>
        </ul>
      </nav>
    </header>

    <!-- Mobile Menu Start-->
    <div v-show="mobileNav">
      <div
        class="absolute px-5 py-12 block z-30 top-0 left-0 w-full h-full bg-gradient-to-b from-theme-dark-blue via-theme-dark-blue-tp to-theme-dark-blue"
      >
        <div class="flex justify-between items-center">
          <img src="/images/logo-bookmark-white.svg" alt="Logo" />
          <button
            @click="toggleMobileNav()"
            type="button"
            class="focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              class="bi bi-x text-white"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </div>
        <ul
          class="uppercase text-white text-md tracking-widest items-center flex flex-col mt-14"
        >
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-t border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav()" href="#">Home</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-t border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav()" href="#features">Services</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-t border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav()" href="#about">About Us</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-t border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav(), goToLogin()" href="#login">Login</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-t border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav(), goToRegister()" href="#register" >Register</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-t border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav(), goToBlog()" href="#register" >Blog</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav()" href="#faq">FAQ</a>
          </li>
          <li
            class="hover:text-theme-secondary transition duration-200 py-4 border-b border-theme-grayish-blue w-full text-center"
          >
            <a @click="toggleMobileNav()" href="#subscribe">HOW IT WORKS</a>
          </li>
          <li
            class="bg-transparent border-2 rounded px-6 py-2 mt-6 w-full text-center cursor-pointer hover:text-theme-secondary transition duration-200"
          >
            <a @click="toggleMobileNav()" href="#post-order">ORDER NOW</a>
          </li>
        </ul>
        <div class="flex justify-center items-end h-52">
          <ul class="flex space-x-8">
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-facebook text-white hover:text-theme-secondary transition duration-200 cursor-pointer"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                />
              </svg>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-twitter text-white hover:text-theme-secondary transition duration-200 cursor-pointer"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Mobile Menu End-->
  </div>
  <!-- Navbar End -->
</template>