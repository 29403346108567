<template>
  <div>
    <v-row no-gutter class="ma-1">
      <v-col cols="12" md="12" class="d-flex text-justify justify-center">
        <v-card width="820" variant="flat" rounded="sm" class="pa-1">
          <template v-slot:title>
            <div class="text-blue font-weight-bold">Privacy Policy</div>
            <div class="text-caption">Effective Date – 1st October 2024</div>
          </template>
          <template v-slot:text>
            <v-alert>
              <p class="text-caption text-justify">
                At US Writing Services, we are committed to protecting the
                privacy and confidentiality of our clients. This Privacy Policy
                outlines how we collect, use, and protect personal information.
                By using our services, you agree to the terms of this policy.
              </p>
            </v-alert>
            <div class="">
              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >Information We Collect:</span
                >
                <span class="text-caption text-justify">
                  We collect various types of information to provide and improve
                  our services, including:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Personal Information: This includes your name, email
                        address, phone number, and payment details.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Service Information: This includes details about the
                        type of writing service requested.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Non-personal Information: We may collect
                        non-identifiable data such as browser type, IP address,
                        and cookies.</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >How We Use Your Information:</span
                >
                <span class="text-caption text-justify">
                  We use the information we collect in the following ways:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Service Delivery: Used to provide you with the
                        requested writing services.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Confidentiality Assurance: All client-provided
                        documents and information are treated with strict
                        confidentiality.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Improvement of Services: We may use non-personal
                        information to analyze site usage and improve
                        efficiency.</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold">Data Security:</span>
                <span class="text-caption text-justify">
                  We take the security of your data seriously. We implement
                  industry-standard measures to safeguard your information.
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Encryption: We use secure encryption
                        protocols.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Access Control: Only authorized personnel have access
                        to personal information.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Data Retention: We retain personal information only as
                        long as necessary.</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >Third-party Services:</span
                >
                <span class="text-caption text-justify">
                  We use third-party services, such as payment processors, to
                  facilitate transactions. We do not share your personal
                  information for marketing purposes.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold">Client Rights:</span>
                <span class="text-caption text-justify">
                  As a client, you have the right to:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Access: Request access to your personal
                        information.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Correction: Request corrections to inaccurate
                        information.</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Deletion: Request deletion of personal information we
                        hold.</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >Cookies and Tracking Technologies:</span
                >
                <span class="text-caption text-justify">
                  We use cookies to improve functionality. You can adjust your
                  browser settings to refuse cookies.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold"
                  >Changes to This Privacy Policy:</span
                >
                <span class="text-caption text-justify">
                  We reserve the right to update this Privacy Policy. Changes
                  will be posted on this page.
                </span>
              </v-alert>

              <v-alert icon="mdi-square-small" class="my-n4">
                <span class="text-blue font-weight-bold">Contact Us:</span>
                <span class="text-caption text-justify">
                  If you have any questions, please contact us at:
                  <ul>
                    <li>
                      <v-alert icon="mdi-square-small"
                        >Email: writers@uswritingservices.com</v-alert
                      >
                    </li>
                    <li>
                      <v-alert icon="mdi-square-small" class="my-n4"
                        >Phone: +1 (424) 240-5459</v-alert
                      >
                    </li>
                  </ul>
                </span>
              </v-alert>
            </div>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.text-blue {
  color: #1e88e5;
}
</style>
