<template>
  <v-data-table
    :headers="headers"
    :items="orders"
    :sort-by="[{ key: '#', order: 'asc' }]"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title
          class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
          >Orders</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="search"
              density="compact"
              variant="outlined"
              label="Search"
              append-inner-icon="mdi-magnify"
              class="ma-3"
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              class="mb-2"
              dark
              size="large"
              prepend-icon="mdi-plus-thick"
              variant="elevated"
              v-bind="props"
              color="primary"
            >
              New Order
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span
                class="text-caption text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
                >{{ formTitle }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="isValid" @submit.prevent="save">
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                        readonly
                        :rules="rules.fullName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-date-input
                        v-model="editedItem.deadline"
                        label="Deadline"
                        clearable
                        disabled
                        prepend-icon=""
                        readonly
                        density="compact"
                        class=""
                      ></v-date-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.time"
                        :active="menu2"
                        :focus="menu2"
                        density="compact"
                        label="Deadline time"
                        prepend-icon=""
                        readonly
                      >
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          activator="parent"
                          transition="scale-transition"
                        >
                          <v-time-picker
                            v-if="menu2"
                            v-model="editedItem.time"
                            full-width
                            format="24hr"
                            readonly
                            :allowed-hours="allowedHours"
                            :allowed-minutes="allowedMinutes"
                          ></v-time-picker>
                        </v-menu>
                      </v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="editedItem.amount"
                        label="Amount(usd)"
                        color="primary"
                        readonly
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.academicLevelId"
                        label="Select Academic Level"
                        :items="academicLevels"
                        item-value="id"
                        item-text="title"
                        readonly
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.citationId"
                        label="Select Citation"
                        :items="citations"
                        readonly
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.subjectId"
                        label="Select Subject"
                        :items="subjects"
                        readonly
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.pages"
                        label="Select Pages"
                        :items="pageNumbers"
                        readonly
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.sources"
                        label="Select Sources"
                        readonly
                        :items="numberItems"
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.spacing"
                        label="Select Spacing"
                        :items="spacingOptions"
                        readonly
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.projectTypeId"
                        label="Select Project Type"
                        :items="projectTypes"
                        readonly
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-autocomplete
                        v-model="editedItem.writerId"
                        label="Select Writer"
                        :items="users"
                        disabled
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-file-input
                        label="Upload Files(optional)"
                        v-model="editedItem.files"
                        prepend-icon=""
                        counter
                        multiple
                        show-size
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-file-input
                        v-model="editedItem.final_file"
                        label="upload Final File"
                        prepend-icon=""
                        counter
                        show-size
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="editedItem.description"
                        label="Descriptions"
                        readonly
                        color="primary"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="editedItem.comments"
                        label="Comments(client comments)"
                        color="primary"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="12"
                      class="text-center"
                      v-for="(document, index) in editItem.documents"
                      :key="index"
                    >
                      <v-card elevation="0">
                        <v-card-title>
                          <div class="text-caption text-primary ml-n3">
                            {{ "download uploaded File" + " " + (index + 1) }}
                          </div>
                        </v-card-title>
                        <v-btn
                          class="me-2"
                          color="success"
                          size="x-small"
                          icon="mdi-download"
                          variant="outlined"
                          @click="
                            handleDownload(
                              document.content,
                              'uploaded_file_' + index
                            )
                          "
                        >
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions class="mr-7 mt-n7 mb-4">
              <v-spacer></v-spacer>
              <v-btn color="error" variant="outlined" @click="close">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                variant="outlined"
                :disabled="!isValid"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="mx-10">
            <v-card-title class="text-subtitle-2 text-center text-primary">
              You sure you want to delete this user?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" variant="outlined" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                variant="outlined"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.description`]="{ item }">
      {{ item.description ? item.description : "N/A" }}
    </template>
    <template v-slot:[`item.deadline`]="{ item }">
      {{ formatDate(item.deadline) }}
    </template>
    <template v-slot:[`item.final_file`]="{ item }">
      <v-btn
        class="me-2"
        color="success"
        size="x-small"
        icon="mdi-download"
        variant="outlined"
        v-if="item.final_file"
        @click="handleDownload(item.final_file, item.title)"
      >
      </v-btn>
      <div v-else class="text-error ml-n2">{{ "N/A" }}</div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        class="me-2"
        color="primary"
        size="x-small"
        variant="outlined"
        icon="mdi-pencil"
        @click="editItem(item)"
      ></v-btn>
      <v-btn
        class="me-2"
        color="error"
        size="x-small"
        icon="mdi-delete"
        variant="outlined"
        @click="deleteItem(item)"
      ></v-btn>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" variant="outlined" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import OrderService from "../services/orders.services.js";
import AcademicLevelService from "../services/academic.levels.services.js";
import BaseService from "../services/base.service";
import PaymentService from "../services/payment.service.js";
import UsersService from "../services/user.service.js";

import moment from "moment";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isValid: false,
    orderId: null,
    time: null,
    menu2: false,
    numberItems: Array.from({ length: 20 }, (_, i) => i + 1),
    pageNumbers: Array.from({ length: 300 }, (_, i) => ({
      title: i + 1,
      id: i + 1,
    })),

    spacingOptions: [
      { title: "Single", id: "single" },
      { title: "1.5", id: "1.5" },
      { title: "Double", id: "double" },
    ],
    rules: {
      fullName: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a full name.";
        },
      ],
      email: [
        (value) => {
          if (value?.length >= 1) return true;
          return "You must enter a slug.";
        },
      ],
      cost: [
        (value) => {
          if (value) return true;
          return "You must enter a cost.";
        },
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: true,
        key: "id",
      },
      { title: "Title", value: "title", key: "title" },
      { title: "Deadline", value: "deadline", key: "deadline" },
      // { title: "Amount (USD)", value: "amount", key: "amount" },
      { title: "Citation", value: "citation.title", key: "citation.title" },
      {
        title: "Academic Level",
        value: "academicLevel.title",
        key: "academicLevel.title",
      },
      { title: "Subject", value: "subject.title", key: "subject.title" },
      { title: "Pages", value: "pages", key: "pages" },
      { title: "Sources", value: "sources", key: "sources" },
      { title: "Spacing", value: "spacing", key: "spacing" },
      { title: "Word Count", value: "wordCount", key: "wordCount" },
      // {
      //   title: "Download Project",
      //   value: "final_file",
      //   align: "center",
      //   key: "final_file",
      // },
      { title: "Actions", key: "actions", sortable: false },
    ],

    status: [
      {
        title: "Active",
        value: 1,
      },
      {
        title: "Deactivated",
        value: 0,
      },
    ],

    editedIndex: -1,
    orders: [],
    academicLevels: [],
    projectTypes: [],
    citations: [],
    subjects: [],
    users: [],
    editedItem: {
      title: "",
      projectTypeId: "",
      subjectId: "",
      deadline: new Date(),
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      academicLevelId: "",
      citationId: "",
      sources: "",
      pages: "",
      spacing: "",
      email: "",
      files: [],
      writerId: "",
      final_file: "",
      wordCount: "",
      amount: "",
      description: "",
    },
    defaultItem: {
      title: "",
      projectTypeId: "",
      subjectId: "",
      deadline: null,
      academicLevelId: "",
      citationId: "",
      writerId: "",
      time: null,
      email: "",
      files: [],
      final_file: "",
      sources: "",
      pages: "",
      spacing: "",
      wordCount: "",
      amount: "",
      description: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Order" : "Edit Order";
    },
    loggedInUser() {
      return this.$store.state.auth.user;
    },
    user() {
      return this.loggedInUser || {};
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.pages": "updateWordCount",
    "editedItem.deadline": "fetchCpp",
    "editedItem.academicLevelId": "fetchCpp",

    "editedItem.pages": "updateAmount",
    "editedItem.cpp": "updateAmount",

    "editedItem.spacing": {
      handler(newVal, oldVal) {
        this.handleChanges();
      },
      deep: true,
    },
  },

  created() {
    this.getWriterOrders();
    this.getacademicLevels();
    this.getCitations();
    this.getProjectTypes();
    this.getSubjects();
    this.getUsers();
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    handleChanges() {
      this.updateWordCount();
      this.updateAmount();
    },

    updateWordCount() {
      const { pages, spacing } = this.editedItem;
      // const pages = this.editedItem.pages;
      // const spacing = this.editedItem.spacing;
      if (pages === null || spacing === null) {
        this.editedItem.wordCount = 0;
        return;
      }

      let wordsPerPage;
      switch (spacing) {
        case "single":
          wordsPerPage = 550;
          break;
        case "1.5":
          wordsPerPage = 275;
          break;
        case "double":
          wordsPerPage = 275;
          break;
        default:
          wordsPerPage = 275;
      }

      const calculatedWordCount = pages * wordsPerPage;
      this.editedItem.wordCount = isNaN(calculatedWordCount)
        ? ""
        : calculatedWordCount;
    },

    getWriterOrders() {
      return OrderService.writerOrders(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            this.orders = response.data.data;
            console.log("we are here", this.orders);
            this.loading = false;
          } else {
            this.orders = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.orders = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getacademicLevels() {
      return AcademicLevelService.index().then(
        (response) => {
          if (response.status == 200) {
            this.academicLevels = response.data.data;
            this.loading = false;
          } else {
            this.academicLevels = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.academicLevels = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getUsers() {
      return UsersService.writer().then(
        (response) => {
          if (response.status == 200) {
            // this.users = response.data.data;
            this.users = response.data.data.map((user) => {
              return {
                ...user,
                title: user.name, // Set user.name to user.title
              };
            });
            this.loading = false;
          } else {
            this.users = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.users = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getCitations() {
      return BaseService.getCitations().then(
        (response) => {
          if (response.status == 200) {
            this.citations = response.data.data;
            this.loading = false;
          } else {
            this.citations = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.citations = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getProjectTypes() {
      return BaseService.getProjectTypes().then(
        (response) => {
          if (response.status == 200) {
            this.projectTypes = response.data.data;
            this.loading = false;
          } else {
            this.projectTypes = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.projectTypes = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getSubjects() {
      return BaseService.getSubjects().then(
        (response) => {
          if (response.status == 200) {
            this.subjects = response.data.data;
            this.loading = false;
          } else {
            this.subjects = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.subjects = [];

          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    fetchCpp() {
      const { deadline, academicLevelId } = this.editedItem;
      if (deadline && academicLevelId) {
        this.loading = true;
        return BaseService.showCpp({ deadline, academicLevelId }).then(
          (response) => {
            if (response.status == 200) {
              this.cpp = response.data.data;
              this.editedItem.cpp = response.data.data.cost;
              this.loading = false;
            } else {
              this.cpp = [];
              this.loading = false;
              console.log(response.data.console.error);
            }
          },
          (error) => {
            this.cpp = [];
            console.log(error);
            if (error.status == 404) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else {
              this.$store.dispatch("alert/error", error.message);
            }
          }
        );
      } else {
        this.editedItem.cpp = null;
        this.updateAmount();
      }
    },
    updateAmount() {
      const { pages, cpp, spacing } = this.editedItem;
      if (pages && cpp !== null) {
        this.editedItem.amount = pages * cpp;
        spacing === "single"
          ? (this.editedItem.amount *= 2)
          : this.editedItem.amount;
        this.editedItem.amount = isNaN(this.editedItem.amount)
          ? ""
          : this.editedItem.amount;
      } else {
        this.editedItem.amount = "";
      }
    },
    editItem(item) {
      console.log("ite", item);
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editItem.documents = item.documents;
      this.editedItem = {
        id: item.id,
        title: item.title || "",
        projectTypeId: item.projectType.id || "",
        subjectId: item.subject.id || "",
        deadline: item.deadline ? new Date(item.deadline) : new Date(), // Convert to Date object if necessary
        time: new Date(item.deadline).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        academicLevelId: item.academicLevel?.id || "", // Get academicLevel id if available
        amount: item.amount || "",
        citationId: item.citation?.id || "", // Get citation id if available
        client: item.client || {},
        comments: item.comments || "",
        documents: item.documents || [], // Ensure documents is an array
        final_file: item.final_file || "",
        pages: item.pages || "",
        payment: item.payment || null,
        projectType: item.projectType || {},
        sources: item.sources || "",
        spacing: item.spacing || "",
        writerId: item.writer?.id || "", // Get writer id if available
        wordCount: item.wordCount || "",
        description: item.description || "",
        files: [], // Initialize files as empty
        dialog: true, // If you want to open a dialog
      };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },

    deleteItemConfirm() {
      OrderService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.orders.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSave() {
      this.editedItem.writerId = this.user.id;
      if (this.editedIndex > -1) {
        Object.assign(this.orders[this.editedIndex], this.editedItem);
        OrderService.update(this.editedItem).then(
          (response) => {
            if (response.status === 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
              this.loading = false;
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status === 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.loading = false;
          }
        );
      } else {
        OrderService.store(this.editedItem).then(
          (response) => {
            if (response.status === 201) {
              this.orders.push(this.editedItem);
              this.$store.dispatch("alert/success", response.data.message);
              this.loading = false;
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status === 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.loading = false;
          }
        );
      }
    },

    save() {
      console.log(this.editedItem.files);
      console.log(this.editedItem.final_file);

      if (this.validate()) {
        this.convertFilesToBase64(this.editedItem.files).then((base64Files) => {
          this.editedItem.files = base64Files;
          if (this.editedItem.final_file) {
            this.convertFileToBase64(this.editedItem.final_file).then(
              (base64File) => {
                this.editedItem.final_file = base64File;
                this.handleSave();
              }
            );
          } else {
            this.handleSave();
          }
        });
      }
    },

    convertFilesToBase64(files) {
      return new Promise((resolve) => {
        const base64Files = [];
        if (files && files.length > 0) {
          files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              base64Files.push(e.target.result);
              if (base64Files.length === files.length) {
                resolve(base64Files);
              }
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(file);
          });
        } else {
          resolve([]);
        }
      });
    },

    convertFileToBase64(file) {
      return new Promise((resolve) => {
        if (file && file instanceof File) {
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve(e.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        } else {
          resolve(null);
        }
      });
    },

    pay() {
      if (this.orderId) {
        PaymentService.createPayment({ orderId: this.orderId }).then(
          (response) => {
            if (response.status === 200) {
              window.location.href = response.data.data.approval_url;
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status === 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else {
              this.$store.dispatch("alert/error", error.message);
            }
          }
        );
      } else {
        this.$store.dispatch("alert/error", "Order not found");
      }
    },

    capturePayment(token, orderId) {
      BaseService.capturePayment({ token: token, orderId: orderId }).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              "alert/success",
              "Payment completed successfully!"
            );
            this.$router.push({
              name: "order-confirmation",
              params: { orderId: id },
            });
          } else {
            this.$store.dispatch("alert/error", "Payment capture failed.");
          }
        },
        (error) => {
          console.error("Payment capture error:", error);
          this.$store.dispatch(
            "alert/error",
            error.response.data.message || "Payment capture error."
          );
        }
      );
    },

    handleDownload(base64File, originalFileName) {
      if (!originalFileName) {
        const mimeType = base64File.split(";")[0].split(":")[1];
        const extension = this.getExtensionFromMimeType(mimeType);
        originalFileName = `downloaded_file.${extension || "bin"}`;
      }

      if (base64File) {
        this.downloadFile(
          base64File,
          originalFileName || "downloaded_file.pdf"
        );
      } else {
        this.$store.dispatch("alert/error", "No file available to download.");
      }
    },

    downloadFile(base64File, originalFileName) {
      // Check if base64File already has the data URL prefix
      if (!base64File.startsWith("data:")) {
        base64File = `data:application/octet-stream;base64,${base64File}`;
      }

      const link = document.createElement("a");
      link.href = base64File;
      link.download = originalFileName;

      // Append to the body (required for Firefox)
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    },

    getExtensionFromMimeType(mimeType) {
      const mimeTypes = {
        "image/svg+xml": "svg",
        "application/pdf": "pdf",
        "image/png": "png",
        "image/jpeg": "jpg",
        "text/plain": "txt",
        // Add more mappings as needed
      };
      return mimeTypes[mimeType] || null; // Return null for unknown types
    },
  },
};
</script>